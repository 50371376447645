<template>
    <div class="laboratory">
        <header class="top_advBox">
            <TopAdv />
        </header>
        <LaboratoryPage></LaboratoryPage>
        <BottomIntroduce />
    </div>
</template>

<script>
    import TopAdv from "../../components/TopAdv";
    import LaboratoryPage from './components/LaboratoryPage'
    import BottomIntroduce from "../../components/BottomIntroduce";
    export default {
        name: "Laboratory",
        components: {
            TopAdv,
            BottomIntroduce,
            LaboratoryPage
        },
        created() {
            this.$store.commit('modifyVuexAdvActive',{vuexAdvActive:2})
        }
    }
</script>

<style scoped lang="less">

</style>
